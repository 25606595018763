<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <el-steps style="width: 50%; margin: 0 auto" :active="active" finish-status="success">
      <el-step title="基本信息"></el-step>
      <el-step title="详情"></el-step>
    </el-steps>
    <el-divider/>

    <el-form ref="form" :rules="rules" :model="formData" label-width="250px">
      <div v-show="active === 0">
        <el-form-item prop="title" label="标题">
          <el-input type="textarea" size="small" style="width: 500px" v-model="formData.title"
                    placeholder="请输入标题"></el-input>
        </el-form-item>

        <el-form-item label ="请选择地区">
          <SelectArea width="500px" v-model="city_list"></SelectArea>
        </el-form-item>

        <el-form-item label ="请选择导航">
          <SelectGuides width="500px" v-model="formData.guide_ids"></SelectGuides>
        </el-form-item>

        <el-form-item label="公告类型">
          <el-select v-model="formData.type" style="width: 500px"  placeholder="请选择">
            <el-option label="招标公告" value="10" key="10"></el-option>
            <el-option label="变更公告" value="20" key="20"></el-option>
            <el-option label="中标公告" value="30" key="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面图">
          <UploaderImg :url.sync="formData.ori_url"></UploaderImg>
        </el-form-item>
        <el-form-item label="">
          <el-button size="small" style="margin-top: 50px; margin-left: 50px;" @click="goNext" type="primary">下一步
          </el-button>
          <el-button size="small" type="" @click="cancel">取消
          </el-button>
        </el-form-item>
      </div>
      <div v-show="active === 1">
        <el-form-item label="发布时间">
          <el-date-picker v-model="formData.publish_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="详情">
          <Editor ref="editor" :showBtn="false" :content.sync="formData.content"></Editor>
        </el-form-item>
        <el-form-item label=" ">
          <el-button size="small" type="primary" @click="goPev()">上一步
          </el-button>
          <el-button size="small" type="primary" @click="save">保存</el-button>
          <el-button size="small" type="" @click="cancel">取消
          </el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import {addAPI, editAPI, getDetailAPI} from './api'
import SelectArea from '@/components/select-area/select-area.vue'
import Editor from '@/components/editor/index.vue'
import UploaderImg from '@/components/qiniu-uploader/uploader-img.vue'
import SelectGuides from '@/views/biaoxun/biaoxun/select-guides.vue'

export default {
  name: 'Update',
  components: {SelectArea, Editor, UploaderImg, SelectGuides},
  data() {
    return {
      active: 0,
      city_list: [],
      formData: {
        title: '',
        tag: null,
        type: '',
        city_name: '',
        province_id: 0,
        city_id: 0,
        publish_time: null,
        content: '',
        ori_url: '',
        guide_ids: [],
      },
      rules: {
                title: [
                    {
                        required: true,
                        message: '标题必填',
                        trigger: 'blur'
                    }
                ]
            }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },
  methods: {
    cancel() {
      this.$router.back(-1)
    },
    goNext() {
      if (this.active++ > 1) this.active = 0
    },
    goPev() {
      this.active = 0
    },
    convertToArr(val) {
      // 如果 val 是一个数字或字符串，则将其转换为数组
      if (typeof val === 'number' || typeof val === 'string') {
        return [val]
      }
      // 如果 val 已经是一个数组，则直接返回
      if (Array.isArray(val)) {
        return val
      }
      // 如果 val 为空或 undefined，则返回一个空数组
      return []
    },
    save() {
      this.formData.province_id = this.city_list[0] || ''
      this.formData.city_id = this.city_list[1] || ''
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      const res = await getDetailAPI(this.$route.params.id)
      this.formData = res
      if (this.formData && this.formData.type) {
        this.formData.type = this.formData.type.toString()
      }
      this.formData.content = res?.detail?.content || ''
      this.formData.ori_url = res?.detail?.ori_url || ''
      this.city_list = [];
      if (this.formData.province_id) {
        this.city_list.push(this.formData.province_id)
      }
      if (this.formData.city_id) {
        this.city_list.push(this.formData.city_id)
      }
    }
  }
}
</script>
